import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'
//import shirt from '../../../../content/assets/shirt.png'
import shirt from '../../../../content/assets/shop_felpa_nera.png'
import { Link } from 'gatsby'

//Ti piace questa maglietta?


const Commitment = props => (
  <Section aside title='Shop' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
        Ti piace questa felpa?
        <Link to={"https://amzn.to/3WN6Qg1"} target="_blank">
            <img width="100%" src={shirt} alt="shirt" />
        </Link>
      </Text>
    </Card>
  </Section>
)

export default Commitment
